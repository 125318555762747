import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowComponent } from './arrow/arrow.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ButtonModule } from 'primeng/button';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, ButtonModule],
  declarations: [ArrowComponent],
  exports: [ArrowComponent]
})
export class SharedUiArrowModule {}
