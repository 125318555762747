<!--
  To add a new template remember cretate the template,
  added the correct switch case and update SliderParams
  interface
-->

<ng-container [ngSwitch]="template">
  <ng-container *ngSwitchDefault>
    <div class="no-arrow-template"></div>
  </ng-container>
  <ng-container *ngSwitchCase="1">
    <ng-template [ngTemplateOutlet]="arrowTemplate1"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="2">
    <ng-template [ngTemplateOutlet]="arrowTemplate2"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="3">
    <ng-template [ngTemplateOutlet]="arrowTemplate3"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="4">
    <ng-template [ngTemplateOutlet]="arrowTemplate4"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="5">
    <ng-template [ngTemplateOutlet]="arrowTemplate5"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="6">
    <ng-template [ngTemplateOutlet]="arrowTemplate6"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="7">
    <ng-template [ngTemplateOutlet]="arrowTemplate7"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="8">
    <ng-template [ngTemplateOutlet]="arrowTemplate8"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="9">
    <ng-template [ngTemplateOutlet]="arrowTemplate9"></ng-template>
  </ng-container>
  <ng-container *ngSwitchCase="10">
    <ng-template [ngTemplateOutlet]="arrowTemplate10"></ng-template>
  </ng-container>
</ng-container>

<!-- #1 TEMPLATE - Icon Angle simple -->
<ng-template #arrowTemplate1>
  <fa-icon class="arrow-template-1" [icon]="orientation === 'right' ? faAngleRight : faAngleLeft" size="1x"></fa-icon>
</ng-template>

<!-- #2 TEMPLATE - Image long arrow -->
<ng-template #arrowTemplate2>
  <!-- <img [src]="'assets/arrows/arrow-template2-' + orientation + '.png'" [alt]="'arrow ' + orientation" /> -->
  <fa-icon [icon]="orientation === 'right' ? faLongArrowRight : faLongArrowLeft" size="1x"></fa-icon>
</ng-template>

<!-- #3 TEMPLATE - Icon Chevron -->
<ng-template #arrowTemplate3>
  <fa-icon [icon]="orientation === 'right' ? faChevronRight : faChevronLeft" size="1x"></fa-icon>
</ng-template>

<!-- #4 TEMPLATE - Icon Caret -->
<ng-template #arrowTemplate4>
  <i [class]="'pi pi-caret-' + orientation"></i>
</ng-template>

<!-- #5 TEMPLATE - Icon Long Arrow -->
<ng-template #arrowTemplate5>
  <i [class]="'pi pi-arrow-' + orientation"></i>
</ng-template>

<!-- #6 TEMPLATE - Icon Long Arrow Image-->
<ng-template #arrowTemplate6>
  <fa-icon [icon]="orientation === 'right' ? faCaretRight : faCaretLeft" size="2x"></fa-icon>
</ng-template>

<!-- #7 TEMPLATE - Icon Long Arrow Not Image-->
<ng-template #arrowTemplate7>
  <div
    class="arrow-long-template"
    [ngClass]="{
      'arrow-middle-left': orientation === 'left',
      left: orientation === 'left',
      'arrow-middle-right': orientation === 'right',
      right: orientation === 'right'
    }"
  >
    <div class="arrow-long"></div>
  </div>
</ng-template>

<!-- #8 SVG Arrow -->
<ng-template #arrowTemplate8>
  <ng-container *ngIf="orientation === 'right'">
    <svg class="arrow-template-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(235 259) rotate(180)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(214 238)" />
        <g id="back" transform="translate(214.494 165.504)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M18.964,81.871H3.286l4.289-4.287a.965.965,0,0,0-1.36-1.36L.287,82.153a.965.965,0,0,0,0,1.36l5.928,5.928a.965.965,0,0,0,1.36-1.36L3.286,83.795H18.964a.965.965,0,1,0,0-1.924Z"
            transform="translate(0)"
            fill="var(--carousel-arrow-color, var(--brandColor))"
          />
        </g>
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="orientation === 'left'">
    <svg class="arrow-template-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
      <defs>
        <style>
          .cls-1 {
            fill: none;
          }
        </style>
      </defs>
      <g id="Group_1" data-name="Group 1" transform="translate(-214 -238)">
        <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(214 238)" />
        <g id="back" transform="translate(214.494 165.504)">
          <path
            id="Path_1"
            data-name="Path 1"
            d="M18.964,81.871H3.286l4.289-4.287a.965.965,0,0,0-1.36-1.36L.287,82.153a.965.965,0,0,0,0,1.36l5.928,5.928a.965.965,0,0,0,1.36-1.36L3.286,83.795H18.964a.965.965,0,1,0,0-1.924Z"
            transform="translate(0)"
            fill="var(--carousel-arrow-color, var(--brandColor))"
          />
        </g>
      </g>
    </svg>
  </ng-container>
</ng-template>

<!-- #8 SVG Long Arrow -->
<ng-template #arrowTemplate9>
  <ng-container *ngIf="orientation === 'right'">
    <svg class="right-arrow" xmlns="http://www.w3.org/2000/svg" width="74.189" height="28.851" viewBox="0 0 74.189 28.851">
      <g id="Group_638" data-name="Group 638" transform="translate(0 1.414)">
        <path
          id="Path_500"
          data-name="Path 500"
          d="M4813.513,2260.5l13.011,13.011-13.011,13.011"
          transform="translate(-4755.163 -2260.5)"
          fill="none"
          stroke="var(--carousel-arrow-color)"
          stroke-width="4"
        />
        <line id="Line_41" data-name="Line 41" x1="71.37" transform="translate(0 12.915)" fill="none" stroke="var(--carousel-arrow-color)" stroke-width="4" />
      </g>
    </svg>
  </ng-container>
  <ng-container *ngIf="orientation === 'left'">
    <svg class="left-arrow" xmlns="http://www.w3.org/2000/svg" width="74.189" height="28.851" viewBox="0 0 74.189 28.851">
      <g id="Group_637" data-name="Group 637" transform="translate(-422.68 -2333.086)">
        <path
          id="Path_500"
          data-name="Path 500"
          d="M4826.523,2260.5l-13.011,13.011,13.011,13.011"
          transform="translate(-4388.004 74)"
          fill="none"
          stroke="var(--carousel-arrow-color)"
          stroke-width="4"
        />
        <line
          id="Line_41"
          data-name="Line 41"
          x2="71.37"
          transform="translate(425.5 2347.415)"
          fill="none"
          stroke="var(--carousel-arrow-color)"
          stroke-width="4"
        />
      </g>
    </svg>
  </ng-container>
</ng-template>

<ng-template #arrowTemplate10>
  <fa-icon [icon]="orientation === 'right' ? faArrowRight : faArrowLeft" size="2x"></fa-icon>
</ng-template>
